import { defineMessages } from 'react-intl-next';

export default defineMessages({
    projectsListLinkedLozengeLabel: {
        defaultMessage: 'Linked',
        description: 'Projects list linked lozenge label',
        id: 'projects.list.linked.lozenge.label',
    },
    projectsListUnlinkedLozengeLabel: {
        defaultMessage: 'Unlinked',
        description: 'Projects list unlinked lozenge label',
        id: 'projects.list.unlinked.lozenge.label',
    },
    projectsListLinkButtonText: {
        defaultMessage: 'Link',
        description: 'Projects list link button text',
        id: 'projects.list.link.button.text',
    },
    projectsListUnlinkButtonText: {
        defaultMessage: 'Unlink',
        description: 'Projects list unlink button text',
        id: 'projects.list.unlink.button.text',
    },
});
