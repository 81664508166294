import React from 'react';
import { useIntl } from 'react-intl-next';
import type { PreloadedQuery } from 'react-relay';
import { graphql, usePreloadedQuery } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Text } from '@atlaskit/primitives';
import { ProjectsMappingStatus } from '../types';
import type { projectsList_GetProjectsQuery } from './__generated__/projectsList_GetProjectsQuery.graphql';
import messages from './messages';

export const FetchProjectsQuery = graphql`
    query projectsList_GetProjectsQuery(
        $cloudId: ID!
        $helpCenterId: ID!
        $helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus!
    ) {
        jira {
            jiraProjectsMappedToHelpCenter(
                cloudId: $cloudId
                filter: { helpCenterId: $helpCenterId, helpCenterMappingStatus: $helpCenterMappingStatus }
            ) {
                edges {
                    node {
                        id
                        name
                        avatar {
                            small
                        }
                        lead {
                            id
                            name
                            picture
                        }
                    }
                }
            }
        }
    }
`;

export interface Props {
    queryReference: PreloadedQuery<projectsList_GetProjectsQuery>;
    searchQuery: string;
    selection: string;
}

export const ROWS_PER_PAGE = 20;
const ProjectsList = ({ queryReference, searchQuery, selection }: Props) => {
    const { formatMessage } = useIntl();
    const data = usePreloadedQuery<projectsList_GetProjectsQuery>(FetchProjectsQuery, queryReference);
    const projects = data.jira?.jiraProjectsMappedToHelpCenter?.edges || [];
    const filteredProjects = projects.filter((project) =>
        project?.node?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const head = {
        cells: [
            {
                key: 'project-name',
                content: 'Project',
                shouldTruncate: true,
                isSortable: true,
                width: 35,
            },
            {
                key: 'lead-name',
                content: 'Lead',
                shouldTruncate: true,
                isSortable: true,
                width: 35,
            },
            {
                key: 'status',
                content: 'Status',
                width: 15,
            },
            {
                key: 'action-button',
                content: 'Actions',
                width: 15,
            },
        ],
    };

    const rows = filteredProjects.map((project) => ({
        key: project?.node?.id,
        cells: [
            {
                key: project?.node?.name,
                content: (
                    <Inline alignBlock="center">
                        <Box paddingBlock="space.050" paddingInlineEnd="space.100">
                            {project?.node?.avatar?.small && (
                                <Avatar
                                    size="small"
                                    appearance="square"
                                    src={project?.node?.avatar?.small}
                                    name={project?.node?.avatar?.small}
                                ></Avatar>
                            )}
                        </Box>
                        <Text>{project?.node?.name}</Text>
                    </Inline>
                ),
            },
            {
                key: project?.node?.lead?.name,
                content: (
                    <Inline alignBlock="center">
                        <Box paddingInlineEnd="space.100">
                            <Avatar
                                size="small"
                                appearance="circle"
                                src={project?.node?.lead?.picture}
                                name={project?.node?.lead?.picture}
                            ></Avatar>
                        </Box>
                        <Text>{project?.node?.lead?.name}</Text>
                    </Inline>
                ),
            },
            {
                key: 'status',
                content:
                    selection === ProjectsMappingStatus.LINKED ? (
                        <Lozenge appearance="success">{formatMessage(messages.projectsListLinkedLozengeLabel)}</Lozenge>
                    ) : (
                        <Lozenge>{formatMessage(messages.projectsListUnlinkedLozengeLabel)}</Lozenge>
                    ),
            },
            {
                content: (
                    <Button appearance="link">
                        {selection === ProjectsMappingStatus.LINKED
                            ? formatMessage(messages.projectsListUnlinkButtonText)
                            : formatMessage(messages.projectsListLinkButtonText)}
                    </Button>
                ),
            },
        ],
    }));

    return (
        <DynamicTable
            head={head}
            rows={rows}
            rowsPerPage={ROWS_PER_PAGE}
            defaultPage={1}
            isFixedSize
            defaultSortKey="project-name"
            defaultSortOrder="ASC"
        />
    );
};

export default ProjectsList;
