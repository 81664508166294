import { defineMessages } from 'react-intl-next';

export default defineMessages({
    manageProjectsPageTitle: {
        defaultMessage: 'Help center projects',
        description: 'Help center manage projects page title',
        id: 'manage.projects.page.title.non-final',
    },
    manageProjectsPageDescription: {
        defaultMessage:
            'Mapping your projects to your help center will surface your resources in help center portal sections, virtual agent, search and recently used request forms. <link>Learn more about projects in help centers</link>',
        description: 'Help center manage projects page description',
        id: 'manage.projects.page.description.non-final',
    },
    manageProjectPageToggleForDefaultLinking: {
        defaultMessage: 'Link all new projects to this help center',
        description: 'Toggle label for default linking',
        id: 'manage.projects.page.toggle.default.linking.non-final',
    },
    defaultMappingToggleLabel: {
        defaultMessage: 'Allow default mapping',
        description: 'Toggle label for default mapping',
        id: 'manage.projects.page.default.mapping.toggle.label.non-final',
    },
    linkedFilterLabel: {
        defaultMessage: 'Linked',
        description: 'Label for linked dropdown item',
        id: 'manage.projects.page.linked.dropdown.item.label',
    },
    unlinkedFilterLabel: {
        defaultMessage: 'Unlinked',
        description: 'Label for unlinked dropdown item',
        id: 'manage.projects.page.unlinked.dropdown.item.label',
    },
});
