import type { JiraProjectsHelpCenterMappingStatus } from './projects-list/__generated__/projectsList_GetProjectsQuery.graphql';

// eslint-disable-next-line no-shadow
export enum ProjectsMappingStatus {
    UNLINKED = 'UNLINKED',
    LINKED = 'LINKED',
    ALL = 'ALL',
}

export type StatusTextMap = {
    [key in Partial<JiraProjectsHelpCenterMappingStatus>]?: string;
};
