/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<598bdfe18fc1386fd8be01d96a39cb60>>
 * @relayHash 348c7078735772b750ba25626209182c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8f0a7aa74f426d04108e1b01dbde79e141b35f134e8d1547d99f32d0ae8c687c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectsHelpCenterMappingStatus = "ALL" | "LINKED" | "UNLINKED" | "%future added value";
export type projectsList_GetProjectsQuery$variables = {
  cloudId: string;
  helpCenterId: string;
  helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus;
};
export type projectsList_GetProjectsQuery$data = {
  readonly jira: {
    readonly jiraProjectsMappedToHelpCenter: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar: {
            readonly small: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly lead: {
            readonly id: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly name: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type projectsList_GetProjectsQuery = {
  response: projectsList_GetProjectsQuery$data;
  variables: projectsList_GetProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterMappingStatus"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterId",
        "variableName": "helpCenterId"
      },
      {
        "kind": "Variable",
        "name": "helpCenterMappingStatus",
        "variableName": "helpCenterMappingStatus"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "small",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picture",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "projectsList_GetProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectsList_GetProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8f0a7aa74f426d04108e1b01dbde79e141b35f134e8d1547d99f32d0ae8c687c",
    "metadata": {},
    "name": "projectsList_GetProjectsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b64153d5d81cd4ad678ecece838f6ed4";

export default node;
